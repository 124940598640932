@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "Bahnschrift";
  src: url("../public/images/Fonts/bahnschrift.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
